<template>
  <WebAppLayout>
    <slot />
  </WebAppLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import WebAppLayout from "../components/webAppLayout.vue";
export default defineComponent({
  name: "DefaultLayout",
  components: {
    WebAppLayout,
  },
  setup() {
    const malarkey = useUseMalarkeyConfig();
    const iconColor = computed(() => malarkey.visualFiles.iconColor);
    const appName = computed(() => malarkey.customizations.app.name);
    return {
      iconColor,
      appName,
    };
  },
});
</script>
